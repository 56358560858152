.carousel {
  .carousel-caption {
      top: 50%;
      transform: translateY(-50%);
      text-align: left !important;
      width: 50%;
      bottom: auto;

      h3 {
          font-size: 2.5rem;
      }
  }
}