@use "../../..//styles/variables.scss" as v;

.loading-container {
    width: 100%;
    height: 100%;
    background-color: v.$color3;

    .loading {
        position: absolute;
        width: 320px;
        height: 240px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        @keyframes pulse {
            0% {
                background-color: v.$color1;
                height: 0%;
                box-shadow: 0 0 4px rgba(0, 0, 0, 1);
            }
            20% {
                background: v.$color5;
                height: 100%;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0);
            }
            50% {
                background: v.$color6;
                box-shadow: 0 0 8px #4dd0e180;
            }
            80% {
                background: v.$color5;
                height: 100%;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0);
            }
            100% {
                height: 0%;
                background-color: v.$color4;
                box-shadow: 0 0 4px rgba(0, 0, 0, 1);
            }
        }

        > ul {
            list-style: none;
            position: absolute;
            padding: 0;
            width: 120px;
            height: 120px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        li {
            width: 4px;
            height: 30px;
            border-radius: 2px;
            background: #050505;
            position: absolute;
            left: 50%;
            top: 10px;
            margin-left: -2px;
            transform-origin: 2px 50px;
            box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
        }

        li div {
            border-radius: 2px;
            width: 4px;
            background: v.$color3;
            position: absolute;
            bottom: 0px;
            left: 0px;
            animation: pulse 6s normal infinite;
        }

        ul > li:nth-of-type(1) {
            transform: rotate(0deg);
            box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
        }

        ul > li:nth-of-type(1) > div {
            animation-delay: 100ms;
        }

        ul > li:nth-of-type(2) {
            transform: rotate(30deg);
        }

        ul > li:nth-of-type(2) > div {
            animation-delay: 200ms;
        }

        ul > li:nth-of-type(3) {
            transform: rotate(60deg);
        }

        ul > li:nth-of-type(3) > div {
            animation-delay: 300ms;
        }

        ul > li:nth-of-type(4) {
            transform: rotate(90deg);
        }

        ul > li:nth-of-type(4) > div {
            animation-delay: 400ms;
        }

        ul > li:nth-of-type(5) {
            transform: rotate(120deg);
        }

        ul > li:nth-of-type(5) > div {
            animation-delay: 500ms;
        }

        ul > li:nth-of-type(6) {
            transform: rotate(150deg);
        }

        ul > li:nth-of-type(6) > div {
            animation-delay: 600ms;
        }

        ul > li:nth-of-type(7) {
            transform: rotate(180deg);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);
        }

        ul > li:nth-of-type(7) > div {
            animation-delay: 700ms;
        }

        ul > li:nth-of-type(8) {
            transform: rotate(210deg);
            box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
        }

        ul > li:nth-of-type(8) > div {
            animation-delay: 800ms;
        }

        ul > li:nth-of-type(9) {
            transform: rotate(240deg);
            box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
        }
        ul > li:nth-of-type(9) > div {
            animation-delay: 900ms;
        }

        ul > li:nth-of-type(10) {
            transform: rotate(270deg);
            box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
        }
        ul > li:nth-of-type(10) > div {
            animation-delay: 1000ms;
        }

        ul > li:nth-of-type(11) {
            transform: rotate(300deg);
            box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
        }

        ul > li:nth-of-type(11) > div {
            animation-delay: 1100ms;
        }

        ul > li:nth-of-type(12) {
            transform: rotate(330deg);
            box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
        }

        ul > li:nth-of-type(12) > div {
            animation-delay: 1200ms;
        }

        h4 {
            text-align: center;
            position: absolute;
            bottom: -10%;
            font-family: "Source Sans", sans-serif;
            font-weight: 300;
            color: v.$color4;

            @media screen and (max-width: 576px) {
                display: none;
            }
        }
    }
}
