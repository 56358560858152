@use "../../../styles/variables" as v;

.bread-crumbs {
  display: flex;
  padding: 1rem;
  margin-bottom: 100px;
  
  .crumb {
    display: flex;
    font-size: .9rem;
  
    > a {
      display: flex;
      flex-direction: column;      

      &::after {
        content: "";
        display: inline-block;
        height: 2px;
        width: 100%;
        background-color: v.$color1;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;        
      }

      &:hover::after {
        transform: scaleX(1);
      }
      
    }

    &::after {
      content: ">";
      color: v.$color1;
      margin: 0 .3rem;
    }

    &:last-child::after {
      visibility: hidden;
    }
  }
}