@use "../../../styles/mixins" as m;

.mobile-app-container {
    transform: translateY(50%);

    .card-body {
        @include m.flexbox(row, space-between, center);
        padding: 5rem;

        h2,
        .mobile-app__button-container {
            flex: 1;
        }

        .mobile-app__button-container {
            @include m.flexbox($gap: 1rem);
            button {
                width: 50%;
            }

            @media (max-width: 992px) {
                flex-direction: column;
                button {
                    width: 100%;
                }
            }
        }

        @media (max-width: 992px) {
            @include m.flexbox(column, center, stretch, 2rem);
            padding: 2rem;
        }
    }
}
