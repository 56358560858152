@use "../../../../styles/variables" as v;

.event-card-container {
    height: 100% !important;

    .card-body {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        .image-container {
            overflow: hidden;

            img {
                transition: transform 0.5s;

                &:hover {
                    transform: scale(1.3);
                }
            }
        }

        .image-container + div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .card-title {
        margin: 20px 0;
        color: v.$color2;
    }

    .card-subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: v.$color1;
        padding-top: 15px;
        font-size: 0.8rem;

        div {
            display: flex;
            align-items: center;
            gap: 0.2rem;
        }

        @media (max-width: 1200px) {
            font-size: 0.6rem;
        }
    }
}
