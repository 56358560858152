@use "../../../../styles/variables" as v;

.course-card-container {
    height: 100% !important;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .image-container {
            overflow: hidden;

            img {
                transition: transform 0.5s;
            }

            &:hover {
                img {
                    transform: scale(1.3);
                }
            }
        }
    }

    .card-title {
        margin: 20px 0;
        color: v.$color2;
    }

    .card-subtitle {
        display: flex;
        justify-content: space-between;
        color: v.$color1;
        border-top: 1px solid v.$color4;
        padding-top: 15px;

        div {
            display: flex;
            align-items: center;
        }
    }
}
