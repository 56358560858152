@use "../../../styles/variables" as v;

.not-found-container {
    color: v.$color4;
    background-color: v.$color3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .error-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 106px;
        font-weight: 800;
        margin: 70px 15px;
    }
    .error-container > span {
        display: inline-block;
        position: relative;
    }
    .error-container > span.four {
        width: 136px;
        height: 43px;
        border-radius: 999px;
        background: linear-gradient(
                140deg,
                rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0.07) 43%,
                transparent 44%,
                transparent 100%
            ),
            linear-gradient(
                105deg,
                transparent 0%,
                transparent 40%,
                rgba(0, 0, 0, 0.06) 41%,
                rgba(0, 0, 0, 0.07) 76%,
                transparent 77%,
                transparent 100%
            ),
            linear-gradient(to right, v.$color1, v.$color2);
    }
    .error-container > span.four:before,
    .error-container > span.four:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 999px;
    }
    .error-container > span.four:before {
        width: 43px;
        height: 156px;
        left: 50%;
        bottom: 50%;
        transform: translateY(50%);
        background: linear-gradient(
                128deg,
                rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0.07) 40%,
                transparent 41%,
                transparent 100%
            ),
            linear-gradient(
                116deg,
                rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0.07) 50%,
                transparent 51%,
                transparent 100%
            ),
            linear-gradient(to right, v.$color1, v.$color2);
    }
    .error-container > span.four:after {
        width: 137px;
        height: 43px;
        transform: rotate(-45deg);
        left: -9%;
        bottom: 80%;
        background: linear-gradient(to right, v.$color1, v.$color2);
    }

    .error-container > span.zero {
        vertical-align: text-top;
        width: 156px;
        height: 156px;
        border-radius: 999px;
        background: linear-gradient(
                -45deg,
                transparent 0%,
                rgba(0, 0, 0, 0.06) 50%,
                transparent 51%,
                transparent 100%
            ),
            linear-gradient(to right, v.$color1, v.$color2);

        overflow: hidden;
        animation: bgshadow 5s infinite;
    }
    .error-container > span.zero:before {
        content: "";
        display: block;
        position: absolute;
        transform: rotate(45deg);
        width: 90px;
        height: 90px;
        background-color: transparent;
        left: 0px;
        bottom: 0px;
        background: linear-gradient(
                95deg,
                transparent 0%,
                transparent 8%,
                rgba(0, 0, 0, 0.07) 9%,
                transparent 50%,
                transparent 100%
            ),
            linear-gradient(
                85deg,
                transparent 0%,
                transparent 19%,
                rgba(0, 0, 0, 0.05) 20%,
                rgba(0, 0, 0, 0.07) 91%,
                transparent 92%,
                transparent 100%
            );
    }
    .error-container > span.zero:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 999px;
        width: 70px;
        height: 70px;
        left: 43px;
        bottom: 43px;
        background: v.$color3;
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
    }

    .screen-reader-text {
        position: absolute;
        top: -9999em;
        left: -9999em;
    }

    @keyframes bgshadow {
        0% {
            box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
        }
        45% {
            box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
        }
        55% {
            box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
        }
        100% {
            box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
        }
    }

    h1 {
        text-align: center;
    }
    .zoom-area {
        margin: 0 auto;
        font-size: 19px;
        text-align: center;
    }
    .link-container {
        text-align: center;

        a {
            text-transform: uppercase;
            font-size: 13px;
            background-color: v.$color1;
            padding: 10px 15px;
            border-radius: 0;
            color: v.$color4;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            line-height: 1.5;
            text-decoration: none;
            letter-spacing: 1px;

            &:hover {
                background-color: v.$color2;
            }
        }
    }
}
