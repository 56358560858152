@use "../../../styles/variables" as v;

.page-header-container {
  // background-color: v.$color2;
  background: linear-gradient(90deg, v.$color1 -15%, v.$color2 30%, v.$color2 70%, v.$color1 115%);;
  color: v.$color1;
  padding: 30px 0;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      content: "⚉";
      font-size: 1rem;
      margin: 0 1rem;
    }
  }
}